.awssld--openAnimation {
  --open-animation-perspective: 600px;
  --open-animation-angle: 8deg;
  --open-animation-enter-delay: 85ms; }
  .awssld--openAnimation .awssld__container {
    -webkit-perspective: var(--open-animation-perspective);
            perspective: var(--open-animation-perspective); }
  .awssld--openAnimation .awssld--moveLeft, .awssld--openAnimation .awssld--moveRight {
    -webkit-animation-delay: var(--open-animation-enter-delay);
            animation-delay: var(--open-animation-enter-delay); }
  .awssld--openAnimation .awssld--exit.awssld--moveLeft {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-animation: openRight var(--slider-transition-duration) both var(--transition-bezier);
            animation: openRight var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--openAnimation .awssld--exit.awssld--moveRight {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-animation: openLeft var(--slider-transition-duration) both var(--transition-bezier);
            animation: openLeft var(--slider-transition-duration) both var(--transition-bezier); }

@-webkit-keyframes openLeft {
  45% {
    -webkit-transform: rotateY(calc(var(--open-animation-angle) * -1));
            transform: rotateY(calc(var(--open-animation-angle) * -1)); } }

@keyframes openLeft {
  45% {
    -webkit-transform: rotateY(calc(var(--open-animation-angle) * -1));
            transform: rotateY(calc(var(--open-animation-angle) * -1)); } }

@-webkit-keyframes openRight {
  45% {
    -webkit-transform: rotateY(var(--open-animation-angle));
            transform: rotateY(var(--open-animation-angle)); } }

@keyframes openRight {
  45% {
    -webkit-transform: rotateY(var(--open-animation-angle));
            transform: rotateY(var(--open-animation-angle)); } }

